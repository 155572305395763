import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { OutletContextType } from 'src/components/Providers/types';
import * as S from './styles';

const CheckOS: React.FC = () => {
  const { flow, flowStates, translate } = useDependencyManagerContext();
  const { updateFlowStates } = useOutletContext<OutletContextType>();
  const userAgent = navigator.userAgent;
  const isWindows = userAgent.indexOf('Windows') > -1;

  useEffect(() => {
    if (flow.validations?.operatingSystem && !isWindows) {
      return;
    }

    if (flowStates.osChecked) {
      return;
    }

    updateFlowStates('osChecked', true);
  }, [
    flow.validations?.operatingSystem,
    flowStates.osChecked,
    isWindows,
    updateFlowStates
  ]);

  if (isWindows) {
    return null;
  }

  return (
    <S.Container data-testid="os-check">
      <S.Title>{translate(flow.texts.operatingSystemCheck.title)}</S.Title>
      <S.Description>
        {translate(flow.texts.operatingSystemCheck.description)}
      </S.Description>
    </S.Container>
  );
};

export default CheckOS;

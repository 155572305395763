import React, { useContext, useEffect, useMemo, useState } from 'react'
import IconMenu from '@veneer/core/dist/esm/scripts/icons/icon_menu'
import LogoHp from '@veneer/core/dist/esm/scripts/logos/hp/hp'
import AppHeader from '@veneer/core/dist/esm/scripts/app_header/app_header'
import SideMenu from '@veneer/core/dist/esm/scripts/side_menu'
import SideMenuItem from '@veneer/core/dist/esm/scripts/side_menu_item'
import Tabs from '@veneer/core/dist/esm/scripts/tabs'
import VeneerHeader from '@veneer/core/dist/esm/scripts/header'
import classes from './Header.module.scss'
import { ConfigContext } from '../store/ConfigContext'
import { createPortal } from 'react-dom'
import {
  CmsDataFetchStatus,
  ContentStackContext,
  useContentStack
} from '@jarvis/olex-content-management'
import { ContentStackTypes, Errors, Paths } from '../store/Constants'
import useAnalytics from '../hooks/useAnalytics'
import { ANALYTICS } from '../store/AnalyticsConstants'
import {
  filterSubTabsByPaasAndHpPlus,
  getActionAuxParamsForAnalytics,
  printerFamilyByDerivativeName
} from '../utils/Utils'
import { useHpPlusClient } from '../hooks/useHpPlusClient'

const Header = () => {
  const {
    states,
    sku,
    paas,
    printer,
    header,
    navigation,
    setSelectedTab,
    setPreviousSelectedTab,
    setSku,
    setPrinter,
    localization,
    os,
    isFlowers,
    setError,
    isInstallRedirectURL
  } = useContext(ConfigContext)
  const { livePreviewInfo } = useContext(ContentStackContext)
  const { fireButtonClick, fireCustomScreenDisplayed } = useAnalytics(
    ANALYTICS.SCREENS.HEADER_NAVIGATION
  )
  const [showSideMenu, setShowSideMenu] = useState(false)
  const sideMenuNode = document.getElementById('side-menu-node')

  const { isHpPlus, isHpPlusCountry } = useHpPlusClient()

  const isNotFoundPage = navigation.location.pathname === Paths.not_found

  const query_modifier = (query, newQuery) => {
    if (isInstallRedirectURL) {
      return query
    }
    let conditions = newQuery()
      .where(
        `taxonomies.printer_family`,
        printerFamilyByDerivativeName(printer?.derivativeName)
      )
      .getQuery()

    return query.or(conditions)
  }

  const { pageData } = useContentStack({
    content_type: ContentStackTypes.tde_header,
    query_modifier
  })

  const onNavClick = (id) => {
    setPreviousSelectedTab(header.selectedTab)
    setSelectedTab(id)
  }

  const handleMenuClick = (tabId, subtabId) => {
    setPreviousSelectedTab(header.selectedTab)
    setSelectedTab(tabId)
    header.setSelectedTabItem(tabId, subtabId)
    setShowSideMenu(false)
  }

  const handleLogoClick = () => {
    if (isNotFoundPage) {
      return
    }

    fireButtonClick(ANALYTICS.BUTTONS.HOMEPAGE_HP_LOGO, {
      detail: window.location.pathname,
      actionAuxParams: getActionAuxParamsForAnalytics(
        sku,
        paas,
        localization.country,
        localization.language,
        os
      )
    })
    setSelectedTab(0)
    navigation.push(`/${sku}`)
  }

  const headerNavigation = useMemo(() => {
    return (
      pageData?.data?.tabs?.map((tab, tabKey) => {
        return {
          id: tabKey,
          label: tab.tab_name,
          subtabs:
            filterSubTabsByPaasAndHpPlus({
              subtabs: tab?.subtabs,
              paas,
              isHpPlus,
              isHpPlusCountry
            }).map((subtab, subtabKey) => {
              return {
                id: subtabKey,
                label: subtab.tab_name
              }
            }) || []
        }
      }) || []
    )
  }, [pageData, paas, isHpPlus])

  const headerModelName = useMemo(() => {
    if (livePreviewInfo?.contentTypeUid) {
      return 'LIVE PREVIEW MODE'
    }

    return isNotFoundPage ? '' : printer?.productShortName
  }, [
    isNotFoundPage,
    livePreviewInfo?.contentTypeUid,
    printer?.printerShortName
  ])

  const Logo = ({ size = 32, id = '' }) => {
    return (
      <LogoHp
        data-testid={`hp-logo${id}`}
        className={classes.logo}
        size={size}
        appearance="singlecolor"
      />
    )
  }

  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu)
  }

  const getSideMenuContent = () => {
    return (
      <div className={classes.sideMenu} data-testid="sidemenu">
        <div className={classes.sideMenuHeader}>
          <Logo size={24} id={'sidemenu'} />
          <div>{headerModelName}</div>
        </div>
        <div className={classes.sideMenuContent} data-testid="sidemenu-content">
          {headerNavigation.map((tab) => {
            return (
              <SideMenuItem
                expanded
                key={tab.id}
                label={tab.label}
                onClick={
                  tab.subtabs.length === 0
                    ? () => handleMenuClick(tab.id, 0)
                    : null
                }
              >
                {tab.subtabs.length > 0 &&
                  tab.subtabs.map((subtab) => {
                    return (
                      <SideMenuItem
                        key={subtab.id}
                        label={subtab.label}
                        className={classes.submenu}
                        selected={
                          tab.id === header.selectedTab &&
                          subtab.id ===
                            header.selectedTabItem[header.selectedTab]
                        }
                        onClick={() => handleMenuClick(tab.id, subtab.id)}
                      />
                    )
                  })}
              </SideMenuItem>
            )
          })}
        </div>
      </div>
    )
  }

  useEffect(() => {
    fireCustomScreenDisplayed(ANALYTICS.SCREEN_BY_SUBTAB[header.selectedTab])
  }, [header.selectedTab])

  useEffect(() => {
    if (pageData.status === CmsDataFetchStatus.ERROR) {
      navigation.push(Paths.not_found)
      setSku(null)
      setPrinter(null)
      setError(Errors.cms_header_error)
    }
  }, [pageData.status])

  if (
    pageData.status !== CmsDataFetchStatus.LOADED &&
    pageData.status !== CmsDataFetchStatus.ERROR
  ) {
    return <></>
  }

  return (
    <React.Fragment>
      {!isNotFoundPage &&
        sideMenuNode &&
        createPortal(
          <SideMenu
            className={classes.sideMenu}
            onToggle={toggleSideMenu}
            expanded={showSideMenu}
            defaultExpanded={false}
            hideAppHeader={true}
            responsiveBreakpoint={'1007px'}
          >
            {getSideMenuContent()}
          </SideMenu>,
          sideMenuNode
        )}
      {!isNotFoundPage && (
        <VeneerHeader
          className={classes.header}
          position="relative"
          appearance="dropShadow"
          centerArea={
            !isNotFoundPage &&
            !isInstallRedirectURL && (
              <Tabs
                controlId="default"
                className={classes.tabs}
                onChangeTab={onNavClick}
                selectedTabId={states.header.selectedTab}
                tabs={headerNavigation}
                type="online"
              />
            )
          }
          leadingArea={
            isInstallRedirectURL ? (
              <AppHeader
                className={classes.appHeader}
                appName={headerModelName}
                logo={<Logo />}
                disableResponsive={true}
              />
            ) : (
              <a
                onClick={handleLogoClick}
                className={classes.link}
                data-testid={'homepage-logo'}
              >
                <AppHeader
                  className={classes.appHeader}
                  appName={headerModelName}
                  logo={<Logo />}
                  disableResponsive={true}
                />
              </a>
            )
          }
          trailingArea={
            !isInstallRedirectURL &&
            !isNotFoundPage &&
            !isFlowers && (
              <IconMenu
                data-testid="hamburger-menu"
                className={classes.menuIcon}
                onClick={toggleSideMenu}
                size={24}
              />
            )
          }
        />
      )}
      {headerNavigation &&
        headerNavigation.map((tab) => (
          <div key={tab.id} id={tab.label?.toLowerCase()} />
        ))}
    </React.Fragment>
  )
}

export default Header

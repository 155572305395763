import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  Paths,
  Errors,
  UserOs,
  PrinterFamily,
  LocalStoreKeys
} from './Constants'
import {
  getConfigurationDefinition,
  getOsAndSupportedStatus,
  getStoreData as getLocalStoreData,
  printerFamilyByDerivativeName,
  setStoreData as setLocalStoreData
} from '../utils/Utils'

export class ConfigContextBusiness {
  static redirectsToNotFoundWhenTheSkuIsNotCompatibleWithUSB = ({
    pageDataCache,
    currentPath,
    sku,
    usbPaths,
    navigation,
    setError
  }) => {
    const configurationsPageData = pageDataCache?.get(
      ContentStackTypes.configuration_definitions.content_type_uid
    )
    if (!configurationsPageData || !currentPath || !sku) {
      return
    }
    const skuWithoutUsb = getConfigurationDefinition(
      configurationsPageData.definitions,
      ConfigurationDefinitionsKeys.skuWithoutUsbDefinition
    )

    if ((skuWithoutUsb || []).includes(sku) && usbPaths.includes(currentPath)) {
      if (setError) setError(Errors.sku_incompatible_with_usb)
      navigation.push(Paths.not_found)
    }
  }

  static compatibilityInformation = ({ pageDataCache, userAgent }) => {
    const configurationsPageData = pageDataCache?.get(
      ContentStackTypes.configuration_definitions.content_type_uid
    )
    if (!userAgent || !configurationsPageData) {
      return {}
    }
    const compatibilityDefinitionJSON = getConfigurationDefinition(
      configurationsPageData.definitions,
      ConfigurationDefinitionsKeys.compatibilityDefinitions
    )

    const {
      isMobile,
      os,
      isOsSupported,
      showInstallButton,
      isBrowserSupported
    } = getOsAndSupportedStatus(userAgent, compatibilityDefinitionJSON)

    return {
      isMobile,
      os,
      isOsSupported,
      showInstallButton,
      isBrowserSupported,
      compatibilityDefinition: compatibilityDefinitionJSON
    }
  }

  static navigationRules = ({
    printer = { derivativeName: '' },
    customNavigation,
    sku,
    paas,
    os,
    osWithCompatibilityMessage,
    isBrowserSupported,
    isOsSupported,
    isMobile
  }) => {
    if (!printer) {
      return
    }

    const printerFamily = printerFamilyByDerivativeName(printer.derivativeName)

    if (
      [PrinterFamily.TRILLIUM_BASE, PrinterFamily.TRILLIUM_PLUS].includes(
        printerFamily
      ) &&
      !isMobile
    ) {
      customNavigation.get('alignment').next = 'hp-software-trillium'
      customNavigation.get('driver-not-found').previous = 'hp-software-trillium'
    } else {
      if (paas) {
        if (customNavigation.has('printer-use')) {
          customNavigation.get('alignment').next = 'printer-use'
          customNavigation.get('printer-use').previous = 'alignment'
        } else if (
          customNavigation.has('alignment') &&
          customNavigation.has('hp-software')
        ) {
          customNavigation.get('alignment').next = 'hp-software'
          customNavigation.get('hp-software').previous = 'alignment'
        }
      } else if (
        customNavigation.has('alignment') &&
        customNavigation.has('hp-software')
      ) {
        customNavigation.get('alignment').next = 'hp-software'
        customNavigation.get('hp-software').previous = 'alignment'
      }

      if (
        !customNavigation.has('printer-use') &&
        !customNavigation.has('setup-checklist') &&
        customNavigation.has('alignment') &&
        customNavigation.has('unsupported-os') &&
        !osWithCompatibilityMessage.includes(os) &&
        (!isBrowserSupported || !isOsSupported)
      ) {
        customNavigation.get('alignment').next = 'unsupported-os'
        customNavigation.get('unsupported-os').previous = 'alignment'
      }

      if (os === UserOs.mac) {
        customNavigation.get('select-usb-on-display').next = 'start-airprint'
        customNavigation.get('start-airprint').previous =
          'select-usb-on-display'
      }
    }

    if (sku) {
      const printerSpecific = customNavigation.get(
        'printer-specific-landing-page'
      )
      printerSpecific.path = `/${sku}`
    }
  }

  static saveLastUserAgent = ({
    getLocalStoreData,
    userAgent,
    navigation,
    sku
  }) => {
    const storedUserAgent = getLocalStoreData(LocalStoreKeys.lastUserAgent)
    if (!storedUserAgent) {
      setLocalStoreData(LocalStoreKeys.lastUserAgent, userAgent)
    } else if (userAgent !== storedUserAgent) {
      navigation.push(`/${sku}`)
      setLocalStoreData(LocalStoreKeys.lastUserAgent, userAgent)
    }
  }

  static identifyWeChatBrowser = ({
    currentPath,
    sku,
    setIsWeChat,
    isWeChat
  }) => {
    if (currentPath === `/${sku}`) {
      const searchParams = new URLSearchParams(window.location.search)
      const haveParameterWeChat = searchParams.get('origin') === 'wechat'
      setIsWeChat(haveParameterWeChat)
      setLocalStoreData(LocalStoreKeys.isWeChat, haveParameterWeChat)
    } else {
      if (!isWeChat) {
        setIsWeChat(getLocalStoreData(LocalStoreKeys.isWeChat))
      }
    }
  }

  static convertLocale = (locale) => {
    const pieces = locale.toLowerCase().split('-')
    return {
      country: pieces[1],
      language: pieces[0]
    }
  }

  static localeRedirectRules = ({
    pageDataCache,
    currentPath,
    localization
  }) => {
    const configs = pageDataCache.get(
      ContentStackTypes.configuration_definitions.content_type_uid
    )?.definitions

    const rules = getConfigurationDefinition(
      configs,
      ConfigurationDefinitionsKeys.localeRedirectRule
    )

    if (!configs || !rules || !currentPath) {
      return
    }

    let finalRedirectTo = null
    for (const rule of rules) {
      const { locale, redirectTo } = rule

      if (
        `${localization.language}-${localization.country}` ===
          locale.toLowerCase() &&
        !finalRedirectTo
      ) {
        finalRedirectTo = redirectTo
      }
    }

    if (finalRedirectTo) {
      const parsedRedirectTo = this.convertLocale(finalRedirectTo)
      window.location.href = `/${parsedRedirectTo.country}/${parsedRedirectTo.language}${currentPath}`
    }
  }
}

import DeviceHttpProxyClient from './DeviceHttpProxyClient'
import { XMLParser } from 'fast-xml-parser'

class DeviceHttpProxyLedmClient extends DeviceHttpProxyClient {
  #parser

  constructor(plugin, sessionId, setError) {
    super(plugin, sessionId, setError)
    this.#parser = new XMLParser({
      allowBooleanAttributes: true,
      ignoreDeclaration: true,
      processEntities: false
    })
  }
  // JavaScript does not allow method overloading. But in order to simplify usage around this class,
  // we're maintaining an unused method parameter - "_" - as the implementation defines its own
  // proper parser function.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetch(href, useCache, parseData, handleError, _) {
    const parser = (data) => {
      return this.#parser.parse(data, true)
    }

    return super.fetch(href, useCache, parseData, handleError, parser)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async write(href, payload, method, handleError, _) {
    const options = {
      headers: {
        'Content-Type': 'text/xml'
      }
    }
    return super.write(href, payload, method, handleError, options)
  }
}
export default DeviceHttpProxyLedmClient

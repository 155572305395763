const ROOT_PATH = '/:country/:lang';
const TERMS_PATH = 'hp-terms';
const CHECK_BROWSER_PATH = `${TERMS_PATH}/check-browser`;
const CHECK_CONSENTS_PATH = `${TERMS_PATH}/check-consents`;
const CHECK_GRANTS_PATH = `${TERMS_PATH}/check-grants`;
const CHECK_OS_PATH = `${TERMS_PATH}/check-os`;
const PAGE_NOT_FOUND_PATH = `${TERMS_PATH}/page-not-found`;
const TERMS_ID_PATH = `${TERMS_PATH}/:id`;

export const PATHS = {
  ROOT: ROOT_PATH,
  TERMS: TERMS_PATH,
  CHECK_BROWSER: CHECK_BROWSER_PATH,
  CHECK_CONSENTS: CHECK_CONSENTS_PATH,
  CHECK_GRANTS: CHECK_GRANTS_PATH,
  CHECK_OS: CHECK_OS_PATH,
  PAGE_NOT_FOUND: PAGE_NOT_FOUND_PATH,
  TERMS_ID: TERMS_ID_PATH
};

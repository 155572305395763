import { useContext, useMemo } from 'react'
import {
  getCloudServiceManifestHref,
  getEPrintManifestHref,
  getIccManifestHref,
  getProductConfigDynHref,
  getClaimPostcardHref,
  getEPrintConfigDynHref,
  getIccStatusHref,
  getIccJobHref
} from '../utils/ledm/helpers'
import {
  RESOURCE_URI_FALLBACK,
  EPRINT_REGISTRATION_PAYLOAD
} from 'src/config/constants'
import DeviceHttpProxyLedmClient from 'src/clients/DeviceHttpProxyLedmClient'
import { ErrorContext } from 'src/store/ErrorContext'
import { ConfigContext } from 'src/store/ConfigContext'

const useLedm = () => {
  const { setError } = useContext(ErrorContext)
  const { sessionId, sendDHPRequest: plugin } = useContext(ConfigContext)

  const ledm = useMemo(
    () => new DeviceHttpProxyLedmClient(plugin, sessionId, setError),
    [plugin, sessionId, setError]
  )

  const put = async (href, payload, handleError = true) => {
    return ledm.write(href, payload, 'PUT', handleError)
  }

  const post = async (href, payload) => {
    return ledm.write(href, payload, 'POST')
  }

  const fetchDiscoveryTree = async () => {
    return ledm.fetch(RESOURCE_URI_FALLBACK.LEDM.discoveryTree, true, true)
  }

  const fetchProductConfigDyn = async (servicesDiscovery) => {
    return ledm.fetch(getProductConfigDynHref(servicesDiscovery), true, true)
  }

  const fetchEPrintManifest = async (servicesDiscovery) => {
    return ledm.fetch(getEPrintManifestHref(servicesDiscovery), true, true)
  }

  const fetchIccManifest = async (servicesDiscovery) => {
    return ledm.fetch(getIccManifestHref(servicesDiscovery), true, true)
  }

  const fetchGen2CloudServiceManifest = async (servicesDiscovery) => {
    const href = getCloudServiceManifestHref(servicesDiscovery)
    return ledm.fetch(href, true, true)
  }

  const fetchClaimPostcard = async (cloudServiceManifest) => {
    const href = getClaimPostcardHref(cloudServiceManifest)
    return ledm.fetch(href, true, false, false)
  }

  const fetchEPrintRegistrationState = async (ePrintManifest) => {
    const href = getEPrintConfigDynHref(ePrintManifest)
    const resp = await ledm.fetch(href, false, true, false)

    return {
      state: resp?.body?.data?.['ep:ePrintConfigDyn']?.['ep:RegistrationState'],
      statusCode: resp?.statusCode,
      errorType: resp?.errorType,
      optIn:
        resp?.body?.data?.['ep:ePrintConfigDyn']?.['ep:AllowedServices']?.[
          'ep:OptIn'
        ]
    }
  }

  const patchEPrintRegistrationState = async (ePrintManifest) => {
    const href = getEPrintConfigDynHref(ePrintManifest)
    return await put(href, EPRINT_REGISTRATION_PAYLOAD)
  }

  const fetchIccStatus = async (iccManifest) => {
    const href = getIccStatusHref(iccManifest)
    const response = await ledm.fetch(href, false, true, false)
    return {
      errorDetails:
        response?.body?.data?.['ut:InternetConnectionStatus']?.[
          'ut:ErrorDetails'
        ]?.['ut:Type'],
      state:
        response?.body?.data?.['ut:InternetConnectionStatus']?.['ut:State'],
      statusCode: response?.statusCode,
      errorType: response?.errorType,
      version: undefined
    }
  }

  const patchIccJob = async (iccManifest) => {
    const href = getIccJobHref(iccManifest)
    return await put(href, null, false)
  }

  return {
    get: ledm.fetch.bind(ledm),
    fetchDiscoveryTree,
    fetchProductConfigDyn,
    fetchEPrintManifest,
    fetchIccManifest,
    fetchGen2CloudServiceManifest,
    post,
    put,
    fetchClaimPostcard,
    fetchEPrintRegistrationState,
    patchEPrintRegistrationState,
    fetchIccStatus,
    patchIccJob
  }
}

export default useLedm

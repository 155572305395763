import { useContext, useMemo } from 'react'
import {
  getAvatarRegistrationHref,
  getClaimPostcardHref,
  getFingerprintHref,
  getSystemIdentityHref,
  getInternetDiagnosticsHref
} from '../utils/cdm/helpers'
import {
  CDM_DEFAULT_VERSION,
  REGISTRATION_STATE,
  RESOURCE_URI_FALLBACK
} from 'src/config/constants'
import DeviceHttpProxyCdmClient from 'src/clients/DeviceHttpProxyCdmClient'
import { ErrorContext } from 'src/store/ErrorContext'
import { ConfigContext } from 'src/store/ConfigContext'

const useCdm = () => {
  const { setError } = useContext(ErrorContext)
  const { sessionId, sendDHPRequest: plugin } = useContext(ConfigContext)
  const cdm = useMemo(
    () => new DeviceHttpProxyCdmClient(plugin, sessionId, setError),
    [plugin, sessionId, setError]
  )

  const put = async (href, payload, handleError = true) => {
    return cdm.write(href, payload, 'PUT', handleError)
  }

  const patch = async (href, payload, handleError = true) => {
    return cdm.write(href, payload, 'PATCH', handleError)
  }

  const fetchServicesDiscovery = async (handleError = true) => {
    return cdm.fetch(
      RESOURCE_URI_FALLBACK.CDM.discoveryTree,
      true,
      true,
      handleError
    )
  }

  const fetchSystemIdentity = async (servicesDiscovery) => {
    const href = getSystemIdentityHref(servicesDiscovery)
    return cdm.fetch(href, true, true)
  }

  const fetchFingerprint = async (servicesDiscovery) => {
    const href = getFingerprintHref(servicesDiscovery)
    return cdm.fetch(href, true, false, false)
  }

  const fetchClaimPostcard = async (servicesDiscovery) => {
    const href = getClaimPostcardHref(servicesDiscovery)
    return cdm.fetch(href, true, false, false)
  }

  const fetchAvatarRegistration = async (servicesDiscovery) => {
    const href = getAvatarRegistrationHref(servicesDiscovery)
    const response = await cdm.fetch(href, false, true, false)

    return {
      registrationUri: response?.body?.data?.links
        ? response.body.data.links.find(
            (link) => link['rel'] === REGISTRATION_STATE.NEW_REGISTRATION
          )?.href
        : null,
      cloudServicesEnabled: response?.body?.data?.cloudServicesEnabled,
      registrationState: response?.body?.data?.registration?.registrationState,
      statusCode: response?.statusCode,
      version: response?.body?.data?.version || CDM_DEFAULT_VERSION,
      errorType: response?.errorType
    }
  }

  const putAvatarRegistration = async (newRegistrationUri, version) => {
    const payload = JSON.stringify({
      version: version,
      registrationTriggeredBy: 'sw'
    })
    return await put(newRegistrationUri, payload)
  }

  const fetchInternetDiagnostics = async (servicesDiscovery) => {
    const href = getInternetDiagnosticsHref(servicesDiscovery)
    const response = await cdm.fetch(href, false, true, false)
    return {
      errorDetails: response?.body?.data?.failureReason,
      state: response?.body?.data?.state,
      version: response?.body?.data?.version || CDM_DEFAULT_VERSION,
      statusCode: response?.statusCode,
      errorType: response?.errorType
    }
  }

  const patchInternetDiagnostics = async (servicesDiscovery, version) => {
    const href = getInternetDiagnosticsHref(servicesDiscovery)
    const payload = JSON.stringify({
      version: version,
      state: REGISTRATION_STATE.PROCESSING
    })
    return patch(href, payload, false)
  }

  return {
    get: cdm.fetch.bind(cdm),
    put,
    patch,
    fetchServicesDiscovery,
    fetchSystemIdentity,
    fetchFingerprint,
    fetchClaimPostcard,
    fetchAvatarRegistration,
    putAvatarRegistration,
    fetchInternetDiagnostics,
    patchInternetDiagnostics
  }
}

export default useCdm

import React from 'react'
import Layout from './Layout'
import ConfigProvider from '../store/ConfigContext'
import { FeatureFlagsProvider } from '../store/FeatureFlagsContext'
import { ContentStackProvider } from '@jarvis/olex-content-management'
import { DirectionProvider } from '@veneer/core/dist/esm/scripts/direction'
import '../styles/global.scss'

const TDELayout = ({
  stack,
  navigation,
  localization,
  featureFlags,
  contentStackCredential,
  analytics,
  userAgent,
  compatibilityDefinition,
  children
}) => {
  return (
    <ContentStackProvider
      credential={contentStackCredential}
      locale={localization}
    >
      <DirectionProvider>
        <FeatureFlagsProvider featureFlags={featureFlags}>
          <ConfigProvider
            analytics={analytics}
            navigation={navigation}
            localization={localization}
            stack={stack}
            contentStackCredential={contentStackCredential}
            userAgent={userAgent}
            compatibilityDefinition={compatibilityDefinition}
          >
            <Layout>{children}</Layout>
          </ConfigProvider>
        </FeatureFlagsProvider>
      </DirectionProvider>
    </ContentStackProvider>
  )
}

export default TDELayout

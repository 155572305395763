import { generateError, isDHPError, Logger } from 'src/utils/helpers'
import { SERVICE_ID } from 'src/config/constants'

class DeviceHttpProxyClient {
  //private attributes
  #plugin
  #sessionId
  #setError

  constructor(plugin, sessionId, setError) {
    this.#plugin = plugin
    this.#setError = setError
    this.#sessionId = sessionId
  }

  async request(
    payload,
    useCache,
    handleError,
    parseData = true,
    parser = (data) => data
  ) {
    Logger.log(`Fetching DHP - payload ${JSON.stringify(payload)}`)
    const response = await this.#plugin(payload, useCache)
    const payloadData = payload?.body?.data || null

    if (handleError && isDHPError(response)) {
      this.#setError(
        generateError({
          ...response,
          payload: payloadData,
          resourceUri: payload.path
        })
      )
      return null
    }

    if (response?.body?.data) {
      const data = response.body.isBase64Encoded
        ? atob(response.body.data)
        : response.body.data
      try {
        response.body.data = parseData ? parser(data) : data
      } catch (e) {
        if (handleError) {
          this.#setError(
            generateError({
              errorType: 'unableToDecode',
              reason: e,
              payload: payloadData,
              resourceUri: payload.path
            })
          )
          return null
        }
      }
    }

    Logger.log(`Fetched DHP - response ${JSON.stringify(response)}`)
    return response
  }

  async fetch(
    href,
    useCache = false,
    parseData = false,
    handleError = true,
    parser = null
  ) {
    const options = {
      path: href,
      sessionId: this.#sessionId,
      method: 'GET',
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return this.request(options, useCache, handleError, parseData, parser)
  }

  async write(href, payload, method, handleError = true, customOptions = {}) {
    let options = {
      path: href,
      sessionId: this.#sessionId,
      method: method,
      body: { data: payload },
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    options = {
      ...options,
      ...customOptions
    }

    return this.request(options, false, handleError)
  }
}

export default DeviceHttpProxyClient

import { GRANT_STATES, userOSHelper } from '@jarvis/react-portal-addons';
import * as T from './types';

const { OS } = userOSHelper;

export const CHROME_FLOW: T.FlowType = {
  id: '2ebddd78-a91b-4af1-9ddf-43f693b74b5c',
  description: 'Perfect Print Chrome Plugin',
  redirectUrl:
    'https://chromewebstore.google.com/detail/hp-print-ai/jiokonlfnbmhagedpgaaknpgngbnmcfl?authuser=0&hl=en',
  terms: {
    latest: 'termsAndConditions.terms'
  },
  consents: [
    {
      id: 'khkwltg592jiqeumumnlv7j6ya8mot5wewbeopp4'
    },
    {
      id: '0gvsi4rbknofwylybhdujeuyqpnlxo6hvgy0koqely'
    }
  ],
  grants: [
    {
      grant: 'ws-hp.com/hpPrintAI',
      states: [GRANT_STATES.ENABLED]
    }
  ],
  texts: {
    operatingSystemCheck: {
      title: 'operatingSystemCheck.windows11.title',
      description: 'operatingSystemCheck.windows11.description'
    }
  },
  validations: {
    currentBrowserChrome: true,
    operatingSystem: OS.Windows10
  },
  slug: 'hp-print-ai-chrome-flow'
};

export const EXCEL_FLOW: T.FlowType = {
  id: '1e565d58-6036-475b-a9d9-153b90e91d10',
  description: 'Perfect Print Excel Add-in',
  redirectUrl: 'https://appsource.microsoft.com',
  terms: {
    latest: 'termsAndConditions.terms'
  },
  consents: [
    {
      id: 'khkwltg592jiqeumumnlv7j6ya8mot5wewbeopp4'
    },
    {
      id: '0gvsi4rbknofwylybhdujeuyqpnlxo6hvgy0koqely'
    }
  ],
  grants: [
    {
      grant: 'ws-hp.com/hpPrintAI',
      states: [GRANT_STATES.ENABLED]
    }
  ],
  texts: {
    operatingSystemCheck: {
      title: 'operatingSystemCheck.windows11.title',
      description: 'operatingSystemCheck.windows11.description'
    }
  },
  validations: {
    operatingSystem: OS.Windows10
  },
  slug: 'hp-print-ai-excel-flow'
};

export const FLOWS: T.FlowType[] = [CHROME_FLOW, EXCEL_FLOW];

export const FLOW_QUERY_PARAM = 'id';

export const getPluginIdFromSearchParams = (
  searchParams: URLSearchParams
): string => {
  const pluginId = searchParams.get('id');

  if (!pluginId) {
    return null;
  }

  return pluginId;
};

export const initialFlowStates: T.FlowStatesType = {
  grantsChecked: false,
  consentsChecked: false,
  browserChecked: false,
  osChecked: false
};

export const flowStatesReducer: T.FlowStatesReducerType = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FLOW_STATE':
      return { ...state, [action.key]: action.value };
    case 'RESET_FLOW_STATES':
      return initialFlowStates;
    default:
      return state;
  }
};

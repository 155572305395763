import classes from './Dialog.module.scss'
import React from 'react'
import { useContentStack } from '@jarvis/olex-content-management'

const Dialog = ({ contentStackInfo }) => {
  const { pageData } = useContentStack({
    content_type: contentStackInfo
  })

  let finalContent = pageData?.data?.content || ''

  return (
    <div className={classes.mainDialog} data-testid="dialog">
      <div
        data-testid="content"
        className={classes.dialog}
        dangerouslySetInnerHTML={{ __html: finalContent }}
      />
    </div>
  )
}

export default Dialog

import { default as mfe } from 'src';
import getJWeb from 'src/contexts/dependencyManager/utils/getJWeb';

const isNative = (): bool => {
  const jweb = getJWeb();
  return jweb.isNative ?? false;
};

export const mfeInterface = {
  v1: {
    isNative
  }
};

export default mfe;

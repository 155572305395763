import DeviceHttpProxyClient from 'src/clients/DeviceHttpProxyClient'

class DeviceHttpProxyCdmClient extends DeviceHttpProxyClient {
  // JavaScript does not allow method overloading. But in order to simplify usage around this class,
  // we're maintaining an unused method parameter - "_" - as the implementation defines its own
  // proper parser function.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetch(href, useCache, parseData, handleError, _) {
    const parser = (data) => {
      return JSON.parse(data)
    }
    return super.fetch(href, useCache, parseData, handleError, parser)
  }
}

export default DeviceHttpProxyCdmClient

import { useContext, useEffect, useMemo, useState } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  Errors,
  LocalStoreKeys,
  Paths,
  PathsThatDoesntNeedSku
} from '../store/Constants'
import { PrinterDetailsClient } from '../clients/PrinterDetailsClient'
import {
  getConfigurationDefinition,
  LOCAL_STORAGE_KEY,
  resetLocalStorage
} from '../utils/Utils'

const ShouldUseMockedPLS = (mockedPLS, stack, printerSKU) => ({
  true: new Promise((res) =>
    res({
      data: JSON.parse(mockedPLS)
    })
  ),
  false: new PrinterDetailsClient(stack).getPrinterDetails(printerSKU)
})

const USE_LOCAL_STORAGE_STATUS = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
}

export const useLocalStorage = () => {
  const [status, setStatus] = useState(USE_LOCAL_STORAGE_STATUS.IDLE)
  const {
    stack,
    getStoreData,
    sku,
    setSku,
    setPrinter,
    navigation,
    setPaas,
    pageDataCache,
    setError,
    isInstallRedirectURL,
    localization
  } = useContext(ConfigContext)

  const mockedPLS = localStorage.getItem('mockedPLS')

  const path = useMemo(
    () => navigation?.location?.pathname?.replace('/', '').replace('/', ''),
    [navigation?.location?.pathname]
  )

  const configurationDefinition = useMemo(
    () =>
      pageDataCache?.get(
        ContentStackTypes.configuration_definitions.content_type_uid
      ),
    [pageDataCache]
  )

  const clear = () => {
    setSku(null)
    setPrinter(null)
    setPaas(false)
  }

  const searchParams = new URLSearchParams(window.location.search)
  const haveSkuSearchParameter = searchParams.get('sku')

  useEffect(() => {
    if (!configurationDefinition) {
      return
    }
    if (isInstallRedirectURL) {
      clear()
      setError(null)
      localStorage.removeItem(LOCAL_STORAGE_KEY)
      setStatus(USE_LOCAL_STORAGE_STATUS.SUCCESS)
      return
    }
    if (status !== USE_LOCAL_STORAGE_STATUS.IDLE) {
      return
    }
    if (Object.values(Paths).includes(`/${path}`) && !haveSkuSearchParameter) {
      if (PathsThatDoesntNeedSku.includes(`/${path}`)) {
        resetLocalStorage(localization?.locale) //reset SKU and printer data if exists
      } else if (Paths.not_found !== `/${path}`) {
        const storedSku = getStoreData(LocalStoreKeys.printer_sku)
        if (storedSku) {
          setSku(storedSku)
        }
        const storedPrinter = getStoreData(LocalStoreKeys.printer)
        setPrinter(storedPrinter)
      }
      setStatus(USE_LOCAL_STORAGE_STATUS.SUCCESS)
    } else if (!sku || sku !== path.toUpperCase()) {
      const friendlyUrlMap = getConfigurationDefinition(
        configurationDefinition?.definitions,
        ConfigurationDefinitionsKeys.friendlyUrl
      )

      const mappedSku = friendlyUrlMap?.[path.toLowerCase()]
      if (mappedSku) {
        navigation.push(`/${mappedSku}`)
      }
      setStatus(USE_LOCAL_STORAGE_STATUS.LOADING)
      const printerSKU =
        searchParams.get('sku') || mappedSku || path.toUpperCase()

      const canceledPrinters = getConfigurationDefinition(
        configurationDefinition?.definitions,
        ConfigurationDefinitionsKeys.printersCanceled
      )

      if (canceledPrinters?.includes(printerSKU)) {
        navigation.push(Paths.not_found)
        clear()
        setError(Errors.canceled_sku_error)
        setStatus(USE_LOCAL_STORAGE_STATUS.ERROR)
      }

      ShouldUseMockedPLS(mockedPLS, stack, printerSKU)
        [!!mockedPLS].then((response) => {
          if (!response.data) {
            navigation.push(Paths.not_found)
            clear()
            setStatus(USE_LOCAL_STORAGE_STATUS.ERROR)
            setError(Errors.pls_empty)
          } else {
            setSku(printerSKU)
            setPrinter({
              ...response.data,
              derivativeName: response.data.derivativeName.trim()
            })
            setPaas(
              !response.data?.onboardingCapabilities?.allowedClients?.includes(
                'portalSMB'
              )
            )
            setStatus(USE_LOCAL_STORAGE_STATUS.SUCCESS)
          }
        })
        .catch((e) => {
          console.error(e)
          navigation.push(Paths.not_found)
          clear()
          setError(Errors.pls_error)
          setStatus(USE_LOCAL_STORAGE_STATUS.ERROR)
        })
    }
  }, [configurationDefinition, status, isInstallRedirectURL])

  return [
    USE_LOCAL_STORAGE_STATUS.SUCCESS,
    USE_LOCAL_STORAGE_STATUS.ERROR
  ].includes(status)
}
